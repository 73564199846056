// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("/home/circleci/project/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("/home/circleci/project/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("/home/circleci/project/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-content-js": () => import("/home/circleci/project/src/pages/Content.js" /* webpackChunkName: "component---src-pages-content-js" */),
  "component---src-pages-d-3-test-js": () => import("/home/circleci/project/src/pages/d3test.js" /* webpackChunkName: "component---src-pages-d-3-test-js" */),
  "component---src-pages-index-js": () => import("/home/circleci/project/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("/home/circleci/project/src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-templates-js": () => import("/home/circleci/project/src/pages/templates.js" /* webpackChunkName: "component---src-pages-templates-js" */)
}

exports.data = () => import("/home/circleci/project/.cache/data.json")

